import { ChevronRight } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { theme } from '@/constants/theme';
import { CardSize } from '@/enums/cards';

import type { ISubPageCardProps } from './types';

const SubPageCard = ({ variant, title, subTitle, onClick, image }: ISubPageCardProps) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Card sx={{ maxWidth: isDesktop ? '350px' : '250px', width: '-webkit-fill-available' }}>
      <CardMedia
        sx={{ height: variant == CardSize.Big ? 260 : 75 }}
        image={image}
        title="Card image"
      />
      <CardContent sx={{ px: '12px', py: variant == CardSize.Big ? 2 : 1 }}>
        <Typography gutterBottom variant="h6" component="h6" sx={{ fontWeight: 700, m: 0 }}>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ textWrap: 'wrap' }}>
          {subTitle}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size={variant == CardSize.Big ? 'medium' : 'small'}
          variant="outlined"
          onClick={onClick}
          endIcon={<ChevronRight />}
        >
          <Typography variant="subtitle2">Learn More</Typography>
        </Button>
      </CardActions>
    </Card>
  );
};

export default SubPageCard;
